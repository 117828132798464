import {Component, NgZone} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService} from '../../services/config.service'
import {FilterService} from '../../services/filter.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  /**
   * The constructor
   *
   * @param configService - Holds the logged in state and tells ut what to show.
   * @param filterService - So that we can reset the filter.
   * @param router - Neeeded to navigate on menu actions from code.
   * @param ngZone - The zone is needed for safe navigation.
   * @param injectedWindow - If we navigate of app we need this.
   */
  constructor(
    public configService: ConfigService,
    public filterService: FilterService,
    private router: Router,
    private ngZone: NgZone,
  ) {
  }

  public logout(): void {
    this.configService.resetToken()
    this.ngZone.run(() => this.router.navigate(['main', 'login']))
  }
}
