import {Injectable} from '@angular/core'
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {catchError} from 'rxjs/operators'
import {throwError} from 'rxjs'
import {ConfigService} from '../services/config.service'


/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private configService: ConfigService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.log('Received 401 error?', error)
          this.configService.resetToken()
        }
        return throwError(error)
      })
    )
  }
}
