<div class="middle-holder">
  <div class="middle">
    <div class="logo">
      Omsättningsverktyg
    </div>
    <div class="filler"></div>
    <div *ngIf="configService.configState | async as config" class="name-holder">
      {{config.user?.name}}
    </div>
    <div *ngIf="configService.configState | async as config" class="middle-menu">
      <button [matMenuTriggerFor]="menu" mat-button>
        <span class="material-symbols-outlined">menu</span>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="filterService.resetFilter()" mat-menu-item>Återställ filter</button>
        <button (click)="logout()" mat-menu-item>Logga ut</button>
      </mat-menu>
    </div>
  </div>
</div>
