import {Inject, Injectable} from '@angular/core'
import {DataService, LoanListItem} from './data.service'
import {BehaviorSubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

export interface FilterData {
  changed: boolean
  letter: boolean
  office: string
  employee: string
  bound: boolean
  unbound: boolean
  business: boolean
  private: boolean

  sort?: SortSetting
}

export interface SortSetting {
  active: string
  direction: 'asc' | 'desc'
}

const FILTER_NAME = 'filter'

@Injectable({
  providedIn: 'root'
})
/**
 * The point of this was to filter the list of loans
 */
export class FilterService {

  public filteredLoans: BehaviorSubject<LoanListItem[]> = new BehaviorSubject<LoanListItem[]>([])

  public officeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  public employeeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  private letterTypes: string[] = ['V', 'O']

  private ownerTypes: string[] = ['B', 'P']

  private bindings: string[] = ['3 må', '1 år', '2 år', '3 år', '4 år', '5 år']

  private loans: LoanListItem[] = []

  private filter: FilterData = {
    changed: false,
    letter: true,
    office: 'all',
    employee: 'all',
    bound: true,
    unbound: true,
    private: true,
    business: true,

    sort: {active: '', direction: 'asc'}
  }

  constructor(
    private dataService: DataService,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.loadFilter()
    this.dataService.loans$.subscribe((loans: LoanListItem[]) => {
      this.loans = loans
      this.officeList.next(this.getSet('office'))
      this.employeeList.next(this.getSet('responsible'))
      this.filteredLoans.next(this.applyFilters())
    })
  }

  public getFilters(): FilterData {
    return this.filter
  }

  public setFilters(data: FilterData): void {
    this.filter = data
    this.filter.sort = this.filter.sort || {active: '', direction: 'asc'}
    this.letterTypes = []
    this.ownerTypes = []
    if (data.changed) {
      this.letterTypes.push('V')
    }
    if (data.letter) {
      this.letterTypes.push('O')
    }
    this.bindings = []

    if (data.bound) {
      this.bindings = ['1 år', '2 år', '3 år', '4 år', '5 år']
    }

    if (data.unbound) {
      this.bindings.push('3 må')
    }

    if (data.business) {
      this.ownerTypes.push('B')
    }

    if (data.private) {
      this.ownerTypes.push('P')
    }

    this.filter.office = data.office
    this.filter.employee = data.employee
    this.filteredLoans.next(this.applyFilters())
    this.injectedLocalStorage.setItem(FILTER_NAME, JSON.stringify(this.filter))
  }

  public resetFilter(): void {
    this.injectedLocalStorage.removeItem(FILTER_NAME)
    this.setFilters({
      changed: false,
      letter: true,
      office: 'all',
      employee: 'all',
      bound: true,
      unbound: true,
      private: true,
      business: true,
      sort: {active: '', direction: 'asc'}
    })
  }

  private loadFilter(): void {
    try {
      this.filter = JSON.parse(this.injectedLocalStorage.getItem(FILTER_NAME) as string) || this.filter
      this.setFilters(this.filter)
      this.filteredLoans.next(this.applyFilters())
    } catch (e) {
      // Can happen and is quite common.
      this.injectedLocalStorage.setItem(FILTER_NAME, JSON.stringify(this.filter))
    }
  }

  /**
   * Returns an array with unique members from the loan list items. E.g. for
   * offices ['Ystad', 'Lomma' ... ]
   *
   * @param property - The property of the loan list item.
   * @private
   */
  private getSet(property: string): string[] {
    return [...new Set(this.loans.map((loan: LoanListItem) => (loan as any)[property]))].sort()
  }

  private applyFilters(): LoanListItem[] {
    return this.loans
      .filter((loan: LoanListItem) => this.letterTypes.indexOf(loan.type) !== -1)
      .filter((loan: LoanListItem) => this.ownerTypes.indexOf(loan.ownerType) !== -1)
      .filter((loan: LoanListItem) => this.bindings.indexOf(loan.frequency) !== -1)
      .filter((loan: LoanListItem) => this.filter.office === 'all' || this.filter.office === loan.office)
      .filter((loan: LoanListItem) => this.filter.employee === 'all' || this.filter.employee === loan.responsible)
  }
}
